import { generateMapByOpts } from 'common/utils'

// 项目状态
const communityStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  },
  {
    text: '作废',
    value: 2
  }
]

const communityStatusMap = generateMapByOpts(communityStatusOps)

// 虚拟项目
const virtualOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '是',
    value: 1
  }, {
    text: '否',
    value: 0
  }
]

const virtualMap = generateMapByOpts(virtualOps)

// 是否考核
const needKpiOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '是',
    value: 1
  }, {
    text: '否',
    value: 0
  }
]

const needKpiMap = generateMapByOpts(needKpiOps)
// 虚拟项目, 用于checkBox
const checkBoxVirtualOps = [
  {
    label: '全部',
    value: undefined
  }, {
    label: '是',
    value: '1'
  }, {
    label: '否',
    value: '0'
  }
]

const checkBoxVirtualMap = generateMapByOpts(checkBoxVirtualOps)

// 管理属性
const manageAttributeOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '绿城',
    value: 1
  }, {
    text: '绿升',
    value: 2
  }, {
    text: '绿联盟',
    value: 3
  }, {
    text: '慧服务',
    value: 4
  }, {
    text: '联营',
    value: 5
  }, {
    text: '术数',
    value: 6
  }, {
    text: '华数',
    value: 7
  }, {
    text: '其它',
    value: 8
  }
]

const manageAttributeMap = generateMapByOpts(manageAttributeOps)

// 业务属性
const serviceAttributeOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '空',
    value: 1
  }, {
    text: '全委',
    value: 2
  }, {
    text: '代管',
    value: 3
  }, {
    text: '专项',
    value: 4
  },
  {
    text: '体验中心',
    value: 5
  }
]

const serviceAttributeMap = generateMapByOpts(serviceAttributeOps)

// 缴费周期
const estatesPeriodTypeOps = [
  {
    text: '每月',
    value: 0
  }, {
    text: '每季度',
    value: 1
  }, {
    text: '每半年',
    value: 2
  }, {
    text: '每年',
    value: 3
  }
]

const estatesPeriodTypeMap = generateMapByOpts(estatesPeriodTypeOps)

// 是否连续缴费
const hasContinuouslyOps = [
  {
    text: '连续缴费',
    value: 0
  }, {
    text: '不连续缴费',
    value: 1
  }
]

const hasContinuouslyMap = generateMapByOpts(hasContinuouslyOps)

// 组织标签
const orgTags = [{
  label: '企业',
  value: 1
},
{
  label: '家庭',
  value: 2
}
]
// 基础信息
// 状态
const _status = {
  0: '关闭',
  1: '正常'
}
// 一级标签
const labelTypeOps = [
  {
    text: '企业',
    value: 4
  }
]
export {
  _status,
  labelTypeOps,
  communityStatusOps,
  communityStatusMap,
  virtualOps,
  virtualMap,
  needKpiOps,
  needKpiMap,
  checkBoxVirtualOps,
  checkBoxVirtualMap,
  manageAttributeOps,
  manageAttributeMap,
  serviceAttributeOps,
  serviceAttributeMap,
  estatesPeriodTypeOps,
  estatesPeriodTypeMap,
  hasContinuouslyOps,
  hasContinuouslyMap,
  orgTags
}
